import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";

// images
import ShieldIcon from "../../assets/featuresIcons/shield-min.png";
import ChatIcon from "../../assets/featuresIcons/chat-min.png";
import AuthenticIcon from "../../assets/featuresIcons/customer-support-min.png";

// styled
const Container = styled.section`
	padding-block: 6rem;

	@media (min-width: 601px) and (max-width: 1024px) {
		padding-block: 4rem;
	}
`;

const Wrapper = styled.div`
	width: 80%;
	margin: auto;

	@media (max-width: 600px) {
		width: 95%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const HeaderContainer = styled.div`
	text-align: center;
	margin-bottom: 6rem;

	@media (max-width: 600px) {
		margin-bottom: 4rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		margin-bottom: 4rem;
	}
`;

const Header = styled.h2`
	font-size: 2.5rem;
	font-weight: 700;
	letter-spacing: 1px;

	@media (max-width: 600px) {
		font-size: 2rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.8rem;
	}
`;

const HeaderColor = styled.span`
	color: var(--MainColor);
`;

const GridContainer = styled.div``;

const GridBox = styled.div`
	text-align: center;

	@media (max-width: 600px) {
		margin-block: 2rem;
	}
`;

const GridImageWrapper = styled.div`
	margin-bottom: 2rem;
`;

const GridImage = styled.img`
	width: 5rem;

	@media (min-width: 601px) and (max-width: 1024px) {
		width: 3rem;
	}
`;

const GridTextWrapper = styled.div``;

const GridLabel = styled.p`
	font-size: 1.1rem;
	font-weight: 700;
	letter-spacing: 0.5px;

	@media (max-width: 600px) {
		font-size: 1.1rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.9rem;
	}
`;

const GridDescription = styled.p`
	font-size: var(--PcFontSizeSmall);
	font-weight: 400;
	letter-spacing: 0.5px;
	width: 70%;
	line-height: 1.5;
	opacity: 0.8;
	margin: 1rem auto 0 auto;

	@media (max-width: 600px) {
		font-size: var(--MobileFontSizeSmall);
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
	}
`;

export default function Features() {
	const data = [
		{
			title: "Trusted",
			description: "We ensure that people who registers on our App are legit and real.",
			image: ShieldIcon,
		},
		{
			title: "Communication",
			description: "Message, write, phone, and share your pictures and videos.",
			image: ChatIcon,
		},
		{
			title: "Authenticity",
			description: "We safety to our users by providing encrypted messaging features.",
			image: AuthenticIcon,
		},
	];
	return (
		<Container>
			<Wrapper>
				<HeaderContainer>
					<Header>
						Our
						<HeaderColor> Features</HeaderColor>
					</Header>
				</HeaderContainer>

				{/* Grid */}
				<GridContainer>
					<Grid container justifyContent='center' spacing={2}>
						{data.map((item, index) => (
							<Grid item key={index} xs={12} sm={4} md={4}>
								<GridBox>
									<GridImageWrapper>
										<GridImage src={item.image} alt={item.title} loading='lazy' />
									</GridImageWrapper>
									<GridTextWrapper>
										<GridLabel>{item.title}</GridLabel>
										<GridDescription>{item.description}</GridDescription>
									</GridTextWrapper>
								</GridBox>
							</Grid>
						))}
					</Grid>
				</GridContainer>
			</Wrapper>
		</Container>
	);
}
