import React from "react";
import styled from "styled-components";
import { SwipeableDrawer, List, ListItem, IconButton, Divider } from "@mui/material";

// Material icons
import CloseIcon from "@mui/icons-material/Close";

// styled-components stlyes
const MobileButtonLink = styled.a`
	width: 100%;
	border-radius: 0.2rem;
`;

const MobileButton = styled.button`
	color: var(--MainColor);
	font-size: 1rem;
	font-weight: 500;
	background: transparent;
	display: flex;
	justify-content: flex-start;
	border: none;
	letter-spacing: 0.5px;
	margin: 0.5rem 0;
	padding: 0.5rem 0;
	width: 100%;
	cursor: pointer;
`;

const SwipeableDrawerMobile = ({ drawer, setDrawer }) => {
	// Drawer Handler for Tablet and Mobile device
	const drawerHandlerClose = (drawer) => {
		setDrawer(!drawer);
	};

	return (
		<SwipeableDrawer anchor='right' open={drawer} onOpen={drawerHandlerClose} onClose={drawerHandlerClose}>
			<List sx={{ width: 280 }}>
				<ListItem sx={{ justifyContent: "flex-end" }}>
					<IconButton onClick={drawerHandlerClose}>
						<CloseIcon sx={{ color: "var(--mainColor)", fontSize: "2rem" }} />
					</IconButton>
				</ListItem>
				<Divider />
				<ListItem>
					<MobileButtonLink href='/' onClick={drawerHandlerClose}>
						<MobileButton>Home</MobileButton>
					</MobileButtonLink>
				</ListItem>
				<Divider />
				<ListItem>
					<MobileButtonLink href='#about_Section' onClick={drawerHandlerClose}>
						<MobileButton>About</MobileButton>
					</MobileButtonLink>
				</ListItem>
				<Divider />
				<ListItem>
					<MobileButtonLink href='#download_Section' onClick={drawerHandlerClose}>
						<MobileButton>Download</MobileButton>
					</MobileButtonLink>
				</ListItem>
				<Divider />
				<ListItem>
					<MobileButtonLink href='#contact_Section' onClick={drawerHandlerClose}>
						<MobileButton>Contact</MobileButton>
					</MobileButtonLink>
				</ListItem>
				<Divider />
			</List>
		</SwipeableDrawer>
	);
};

export default SwipeableDrawerMobile;
