import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";

import ImageProp from "../../assets/mobileProp/Group72-min.png";

// styles
const Container = styled.section`
	padding: 4rem 0;

	@media (max-width: 600px) {
		padding: 2rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 2rem 0;
	}
`;

const Wrapper = styled.div`
	width: 85%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const TextWrapper = styled.div`
	@media (max-width: 600px) {
		text-align: center;
	}
`;

const ImageWrapper = styled.div`
	@media (max-width: 600px) {
		margin-top: 4rem;
	}
`;

const Image = styled.img`
	height: 100%;
	width: 100%;
	object-fit: contain;
	border-radius: 1rem;
`;

const Header = styled.h2`
	font-size: 3rem;
	font-weight: 800;
	letter-spacing: 1px;

	@media (max-width: 600px) {
		font-size: 2rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.8rem;
	}
`;

const HeaderColor = styled.span`
	color: var(--MainColor);
`;

const Description = styled.p`
	color: var(--lightDescriptionColor);
	font-size: var(--PcFontSize);
	margin-top: 2rem;
	font-weight: 400;
	opacity: 0.7;
	line-height: 1.5;
	letter-spacing: 0.5px;
	width: 90%;

	@media (max-width: 600px) {
		width: 90%;
		margin: 2rem auto 2rem auto;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
		width: 90%;
		margin-top: 1rem;
	}
`;

export default function TextFirstGrid() {
	return (
		<Container>
			<Wrapper>
				<Grid container direction='row' justifyContent='space-around' alignItems='center'>
					<Grid item xs={12} sm={6} md={6}>
						<TextWrapper>
							<Header>
								<HeaderColor>More</HeaderColor> Than Just Hello!
							</Header>
							<Description>
								Chat easily with your matches. Use GIFs, Images and emojis to express yourself when
								you're to make your conversations much more interesting.
							</Description>
						</TextWrapper>
					</Grid>
					<Grid item xs={12} sm={4} md={4}>
						<ImageWrapper>
							<Image src={ImageProp} alt='More Than Just Hello!' />
						</ImageWrapper>
					</Grid>
				</Grid>
			</Wrapper>
		</Container>
	);
}
