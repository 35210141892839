import React from "react";
import styled from "styled-components";

// styled
const Container = styled.section`
	padding: 8rem 0 2rem 0;

	@media (max-width: 600px) {
		padding: 6rem 0 0 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 6rem 0 2rem 0;
	}
`;

const Wrapper = styled.div`
	width: 80%;
	margin: auto;

	@media (max-width: 600px) {
		width: 95%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const TextWrapper = styled.div`
	text-align: center;
`;

const HeaderText = styled.h2`
	font-size: 3rem;
	font-weight: 800;
	letter-spacing: 1px;

	@media (max-width: 600px) {
		font-size: 2rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.8rem;
	}
`;

const HeaderColor = styled.span`
	color: var(--MainColor);
`;

const Description = styled.p`
	color: var(--lightDescriptionColor);
	font-size: var(--PcFontSize);
	margin: 2rem auto 0 auto;
	font-weight: 400;
	opacity: 0.7;
	line-height: 1.5;
	letter-spacing: 0.5px;

	@media (max-width: 600px) {
		margin: 2rem auto 2rem auto;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
		margin-top: 1rem;
	}
`;

export default function Header() {
	return (
		<Container>
			<Wrapper>
				<TextWrapper>
					<HeaderText>
						<HeaderColor>Contact </HeaderColor> Us
					</HeaderText>

					<Description>
						Have you got a topic you wish to discuss? We pledge to respond to your message or email as
						quickly as we can.
					</Description>
				</TextWrapper>
			</Wrapper>
		</Container>
	);
}
