import React from "react";
import styled from "styled-components";

// data
import { PrivacyPolicyData } from "./Data";

// styled
const Container = styled.section`
	padding: 10rem 0 2rem 0;
	overflow: hidden;

	@media (max-width: 600px) {
		padding: 6rem 0 2rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 8rem 0 2rem 0;
	}
`;

const Wrapper = styled.div`
	width: 80%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 85%;
	}
`;

const HeaderWrapper = styled.div`
	margin-bottom: 2rem;
`;

const HeaderText = styled.h2`
	font-size: 3rem;
	font-weight: 800;
	letter-spacing: 1px;
	margin-bottom: 2rem;

	@media (max-width: 600px) {
		font-size: 1.6rem;
		text-align: center;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.4rem;
	}
`;

const HeaderColor = styled.span`
	color: var(--MainColor);
`;

const Slogan = styled.p`
	font-size: var(--PcFontSize);
	font-weight: 800;
	letter-spacing: 0.5px;
	opacity: 0.7;
	line-height: 1.5;
	margin-bottom: 1rem;

	@media (max-width: 600px) {
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSize);
	}
`;

const Description = styled.p`
	font-size: var(--PcFontSize);
	letter-spacing: 0.5px;
	opacity: 0.7;
	line-height: 1.5;

	@media (max-width: 600px) {
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
	}
`;

const TextBox = styled.div`
	margin-block: 4rem;

	@media (min-width: 601px) and (max-width: 1024px) {
		margin-block: 2rem;
	}
`;

const QuestionText = styled.h3`
	font-size: 1.4rem;
	margin-bottom: 2rem;
	letter-spacing: 0.5px;

	@media (max-width: 600px) {
		font-size: 1.2rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.8rem;
		margin-bottom: 1rem;
	}
`;

const ParagraphText = styled.p`
	margin-block: 1rem;
	line-height: 1.5;
	opacity: 0.7;
	letter-spacing: 0.5px;

	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
	}
	&:hover {
		opacity: 1;
		transition: all 0.2s ease-in-out;
	}
`;

const ListItem = styled.li`
	margin-block: 0.5rem;
	line-height: 2;
	opacity: 0.7;
	letter-spacing: 0.5px;

	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
	}
`;

export default function PrivacyPolicy() {
	return (
		<Container>
			<Wrapper>
				<HeaderWrapper>
					<HeaderText>
						PRIVACY<HeaderColor> POLICY</HeaderColor>
					</HeaderText>

					<Slogan>Welcome to Ons!</Slogan>
					<Description>
						Ons is owned and operated by Valos IT LTD.
						<br />
						Ons values your privacy and the protection of your personal data. This privacy policy describes
						what information we collect from you, how we collect it, how we use it, how we obtain your
						consent, how long we retain it in our databases and, if necessary, with whom we share it.
						<br />
						<br />
					</Description>

					<Description>
						By registering as a user and using the platform, you are accepting the practices described in
						this privacy policy. Your use of the platform is also subject to our terms and conditions. In
						this privacy policy, the word "platform" refers to the Ons App and website together, "we", "us",
						"our" and "Ons" refer to Ons and "you" and "user" refer to you, the Ons user.
						<br />
						<br />
						This privacy policy may change from time to time. Your continued use of the platform after we
						make changes is deemed acceptance of those changes, so please check the policy periodically for
						updates. This privacy policy has been prepared and is maintained in accordance with all
						applicable national and international laws and regulations and, in particular, with the Data
						Protection Act 2018 (UK regulations) and the GDPR (General Data Protection Regulation - European
						regulations).
					</Description>
				</HeaderWrapper>

				{/* 1 First General Information */}
				<TextBox>
					<QuestionText>
						<HeaderColor>1. </HeaderColor>
						GENERAL INFORMATION
					</QuestionText>

					<ParagraphText>Users' personal data collected through:</ParagraphText>
					<ListItem>
						<strong>Ons App (Available on Google Play and App store).</strong>
					</ListItem>
					<ListItem>https://ons-app.com</ListItem>

					<ParagraphText>Will be under responsibility and in charge of:</ParagraphText>
					<ListItem>
						<strong>Valos IT LTD.</strong>
					</ListItem>
					<ListItem>Email : ons-support@valosit.co.uk</ListItem>
				</TextBox>

				{/* 2 Second TYPES OF INFORMATION GATHERED */}
				<TextBox>
					<QuestionText>
						<HeaderColor>2. </HeaderColor>
						TYPES OF INFORMATION GATHERED
					</QuestionText>

					<ParagraphText>
						The information we collect from our users helps us to deliver our services effectively and to
						personalise and continually improve the user's experience on the platform. These are the types
						of information we collect:
					</ParagraphText>
					<ParagraphText>
						<strong>Information You Give Us: </strong>
						You provide information when you provide, search, read and view content on the platform,
						register as a user, use the functionalities available on the platform, purchase points and/or
						communicate with us through our contact information or contact forms. As a result of those
						actions, you might supply us with the following information:
					</ParagraphText>
					<ListItem>Username</ListItem>
					<ListItem>User's birthday</ListItem>
					<ListItem>Gender</ListItem>
					<ListItem>Relation status</ListItem>
					<ListItem>Sexual orientation</ListItem>
					<ListItem>Phone number</ListItem>
					<ListItem>
						Any additional information relating to you that you provide to us directly or indirectly through
						our website or online presence such as ‘cookies’.
					</ListItem>

					<ParagraphText>
						Ons will not collect any personally identifiable information about you, unless you provide it.
					</ParagraphText>
					<ParagraphText>
						<strong>Information Collected Automatically: </strong>
						By accessing and using the website you automatically provide us with the following information:
					</ParagraphText>

					<ListItem>The device and usage information you use to access the website</ListItem>
					<ListItem>Your IP address </ListItem>
					<ListItem>Browser and device characteristics</ListItem>
					<ListItem>Operating system</ListItem>
					<ListItem>Referring URLs</ListItem>

					<ParagraphText>
						If you access the website through a mobile phone, we will collect the following information:
					</ParagraphText>
					<ListItem>Mobile device ID </ListItem>
					<ListItem>Model and manufacturer </ListItem>
					<ListItem>Operating system </ListItem>
					<ListItem>Version information </ListItem>
					<ListItem>IP address</ListItem>

					<ParagraphText>
						<strong>Location: </strong>
						We store real-time information about the location of users registered in the platform when using
						the services available on the platform, accessing the GPS function built into the mobile devices
						of each user. This information is stored to provide the correct use of the application and
						provide specific services according to the user's location. This information is used for the
						time necessary to provide the services and is stored in the history of each user. Users will be
						able to consult their order history through the platform. However, this information does not
						identify or individualise the person, it is completely anonymous. The user can request the
						removal of such information but certain functionalities of the application would not be
						available. Location information collected through the platform will be treated in accordance
						with this privacy policy.
					</ParagraphText>

					<ParagraphText>
						<strong>Payment Information: </strong>
						Your payment data will be processed by the payment processors available on this website (Stripe,
						Google Pay, Apple Pay), which will process and store your data securely and for the sole purpose
						of processing transactions within the platform. Ons reserves the right to contract any payment
						platform available on the market, which processes your data for the sole purpose of processing
						transactions within the platform.
					</ParagraphText>

					<ParagraphText>View the privacy policy for Stripe, Google Pay and Apple Pay here:</ParagraphText>
					<ListItem>
						<a href='https://stripe.com/gb/privacy'>https://stripe.com/gb/privacy</a>
					</ListItem>
					<ListItem>
						<a href='https://policies.google.com/privacy'>https://policies.google.com/privacy</a>
					</ListItem>
					<ListItem>
						<a href='https://support.apple.com/en-us/HT203027'>https://support.apple.com/en-us/HT203027</a>
					</ListItem>

					<ParagraphText>
						<strong>GOOGLE Analytics: </strong>
						We use Google Analytics provided by Google, Inc., USA (“Google”). These tool and technologies
						collect and analyse certain types of information, including IP addresses, device and software
						identifiers, referring and exit URLs, feature use metrics and statistics, usage and purchase
						history, media access control address (MAC Address), mobile unique device identifiers, and other
						similar information via the use of cookies. The information generated by Google Analytics
						(including your IP address) may be transmitted to and stored by Google on servers in the United
						States. We use the GOOGLE Analytics collection of data to enhance the website and improve our
						service.
					</ParagraphText>
					<ParagraphText>Please consult Google's privacy policy here:</ParagraphText>
					<ListItem>
						<a href='https://policies.google.com/privacy'>https://policies.google.com/privacy</a>
					</ListItem>

					<ParagraphText>
						<strong>Social Media: </strong>
						On our platform you will find links and functions linked to different social networks, in which
						you can share your information.
					</ParagraphText>
					<ParagraphText>
						It is advisable to consult the privacy policy and data protection of each social network used on
						our website.
					</ParagraphText>
					<ListItem>
						Facebook:&nbsp;
						<a href='https://www.facebook.com/privacy/explanation'>
							https://www.facebook.com/privacy/explanation
						</a>
					</ListItem>
					<ListItem>
						Instagram:&nbsp;
						<a href='http://instagram.com/about/legal/privacy/'>
							http://instagram.com/about/legal/privacy/
						</a>
					</ListItem>
				</TextBox>

				{/* Starts from 3*/}
				{PrivacyPolicyData.map(({ number, question, Answer, List }, index) => (
					<TextBox key={index}>
						<QuestionText>
							<HeaderColor>{number} </HeaderColor>
							{question}
						</QuestionText>

						{Answer.map((item, id) => (
							<ParagraphText key={id}>{item.paragraph}</ParagraphText>
						))}
						{List.map((item, id) => (
							<ListItem key={id}>{item.listItem}</ListItem>
						))}
					</TextBox>
				))}
			</Wrapper>
		</Container>
	);
}
