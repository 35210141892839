export const CookiesPrivacyData = [
	{
		number: "1.",
		question: "WHAT ARE COOKIES?",
		Answer: [
			{
				paragraph:
					"Cookies are small text files that are stored on your computer or mobile device when you visit a website. They allow the website to recognise your device and remember if you have been to the website before. Cookies are a very common web technology; most websites use cookies and have done so for years. Cookies are widely used to make the website work more efficiently. Cookies are used to measure which parts of the website users visit and to personalise their experience. Cookies also provide information that helps us monitor and improve the performance of the website.",
			},
		],
		List: [],
	},
	{
		number: "2.",
		question: "REFUSING OR WITHDRAWING CONSENT TO THE USE OF COOKIES",
		Answer: [
			{
				paragraph:
					"If you do not want cookies to be dropped on your device, you can adjust the setting of your Internet browser to reject the setting of all or some cookies and to alert you when a cookies is placed on your device. For further information about how to do so, please refer to your browser ‘help’, ‘tool’, or ‘edit’ section. Please note that if you use your browser settings to block all cookies, including strictly necessary cookies, you may not be able to access or use all or parts of the functionalities of Ons.",
			},
			{
				paragraph:
					"If you want to remove previously-stored cookies, you can manually delete the cookies at any time. However, this will not prevent Ons from placing further cookies on your device unless and until you adjust your Internet browser setting as described above.",
			},
			{
				paragraph:
					"We provide the links for the management and blocking of cookies depending on the browser you use:",
			},
		],
		List: [
			{
				listItem:
					"Microsoft Edge: https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&rs=en-us&ad=us",
			},
			{
				listItem: "Firefox: https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox",
			},
			{
				listItem: "Chrome: https://support.google.com/chrome/answer/95647?hl=en",
			},
			{
				listItem:
					"Safari: https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac",
			},
			{
				listItem: "Android: https://support.google.com/answer/32050",
			},
			{
				listItem: "iOS: https://support.apple.com/en-us/HT201265",
			},
		],
	},
	{
		number: "3.",
		question: "FIRST-PARTY COOKIES",
		Answer: [
			{
				paragraph:
					"We use cookies to enhance the performance of our website and personalise your online Ons experience. Cookies help us to collect information on how people use our website and which pages they visit. They enable us to monitor the number of visitors and to analyse website usage patterns and trends. We collect this information anonymously, so it does not identify anyone as an individual and no personal information is stored in our cookies. We always use cookie data in a responsible way.",
			},
		],
		List: [],
	},
	{
		number: "4.",
		question: "THIRD-PARTY COOKIES",
		Answer: [
			{
				paragraph:
					"Third-party cookies may come from partners or third-party companies that provide functional web services or tools for our website and the optimal functioning and operation of our services. We use third party cookies responsibly and for the sole purpose of providing optimal functioning of the platform and services. You may opt out of these cookies by following the cookie removal information contained in this document or the technical information of the browser from which you access our website and services.",
			},
		],
		List: [],
	},
	{
		number: "5.",
		question: "SESSION COOKIES",
		Answer: [
			{
				paragraph:
					"Session cookies are used to log in to the website with your respective credentials and password. Session cookies are also used to keep users logged in. Session cookies are temporary and are deleted from the device or browser when the session is closed and the browser is closed. We use session cookies to keep the session open when using our services and to identify you on our system each time you log on to the website.",
			},
		],
		List: [],
	},
	{
		number: "6.",
		question: "SOCIAL COOKIES",
		Answer: [
			{
				paragraph:
					"These cookies allow you to interact with social networks through certain actions such as the Like button. They also allow you to interact with the contents of each social network and share website content on social networks. The way these cookies are used and the information collected is governed by the privacy policy of each social network.",
			},
		],
		List: [],
	},
	{
		number: "7.",
		question: "PURPOSES OF OUR COOKIES",
		Answer: [
			{
				paragraph: "Our cookies are used for the following purposes:",
			},
		],
		List: [
			{
				listItem: "Strictly Necessary: These cookies are essential for Ons to perform its basic functions.",
			},
			{
				listItem: "Security: We use these cookies to help identify and prevent potential security risks.",
			},
			{
				listItem:
					"Analytics and Performance: Performance cookies collect information on how users interact with our website, including what pages are visited most, as well as other analytical data. We use these details to improve how our website function and to understand how users interact with them.",
			},
			{
				listItem:
					"GOOGLE Analytics. We use Google Analytics provided by Google, Inc., USA (“Google”). These tool and technologies collect and analyse certain types of information, including IP addresses, device and software identifiers, referring and exit URLs, feature use metrics and statistics, usage and purchase history, media access control address (MAC Address), mobile unique device identifiers, and other similar information via the use of cookies. The information generated by Google Analytics (including your IP address) may be transmitted to and stored by Google on servers in the United States. We use the GOOGLE Analytics collection of data to enhance the website and platform and improve our service.",
			},
		],
	},
	{
		number: "8.",
		question: "CONTACT US",
		Answer: [
			{
				paragraph:
					"If you have questions or concerns about this cookie policy and the handling and security of your data, please contact us through our contact page or via the contact information below:",
			},
		],
		List: [
			{
				listItem: "Valos IT LTD - Ons.",
			},
			{
				listItem: "Email : ons-support@valosit.co.uk",
			},
		],
	},
];

export const PrivacyPolicyData = [
	{
		number: "3. ",
		question: "HOW LONG WE KEEP YOUR DATA",
		Answer: [
			{
				paragraph:
					"Personal data provided by users through the platform will be kept for the time necessary to provide the platform and the functionalities available on the platform or until the user maintains the user account on the platform or decides to close it or until Ons closes and deletes the user account. Ons may retain personal data for a longer period where the user has given consent to such processing, provided that such consent is not withdrawn. In addition, Ons may be obliged to retain personal data for a longer period if this is necessary for compliance with a legal obligation or by order of an authority. Once the retention period expires, the personal data will be deleted. Therefore, the right of access, the right of erasure, the right of rectification and the right to data portability cannot be asserted once the retention period has expired.",
			},
		],
		List: [],
	},

	{
		number: "4.",
		question: "HOW WE USE YOUR INFORMATION",
		Answer: [
			{
				paragraph:
					"In general, we use the information we collect primarily to provide, maintain, protect and improve our platform and services. We use personal information collected through our platform and website as described below:",
			},
		],
		List: [
			{
				listItem: "Provide the platform (Available on Google Play and App store).",
			},
			{
				listItem: "User registration.",
			},
			{
				listItem: "Provide the verification code.",
			},
			{
				listItem: "Provide the functionalities available on the platform.",
			},
			{
				listItem: "Facilitate users' searches. ",
			},
			{
				listItem: "Facilitate communication between users.",
			},
			{
				listItem: "Process payments.",
			},
			{
				listItem: "Understand and enhance your experience using our platform.",
			},
			{
				listItem: "Respond to your comments or questions through our support team.",
			},
			{
				listItem:
					"Send you related information, including confirmations, invoices, technical notices, updates, security alerts and support and administrative messages.",
			},
			{
				listItem:
					"Communicate with you about upcoming events, offers and news about products and services offered by Ons and our selected partners.",
			},
			{
				listItem: "Marketing purposes of Ons.",
			},
			{
				listItem:
					"Link or combine your information with other information we get from third parties to help understand your needs and provide you with better service.",
			},
			{
				listItem: "Protect, investigate and deter against fraudulent, unauthorised or illegal activity.",
			},
		],
	},

	{
		number: "5.",
		question: "HOW DO YOU GET MY CONSENT?",
		Answer: [
			{
				paragraph:
					"By registering as a user, using the functionalities available on the platform, providing information or content through the platform, purchasing points, communicating with us through our contact forms or our contact information, and providing us with personal information to communicate with you, you consent to our use of cookies, you consent to our collection, storage and use of your information on the terms contained in this privacy policy. You may withdraw your consent by sending us your request via the contact information or contact page.",
			},
		],
		List: [],
	},

	{
		number: "6.",
		question: "HOW WE SHARE INFORMATION",
		Answer: [
			{
				paragraph:
					"The personal information of our users is an important and fundamental part of our business. Under no circumstances will we sell or share information with third parties that has not been previously authorised by the user or owner of the personal data. We share user information solely and exclusively as described below.",
			},
		],
		List: [
			{
				listItem:
					"Third-Party Service Providers. We use third-party services to perform certain functions on our behalf and through our website and platform. Examples of these third-party services include: creating and hosting the platform, processing payments (Stripe, Google Pay, Apple Pay), sending verification code, analysing data (Google Analytics), marketing assistance, and delivering search results. These third-party services and tools may have access to personal information needed to perform their functions, but may not use that information for other purposes. Information shared with these third-party services will be treated and stored in accordance with their respective privacy policies and our privacy policy.",
			},
			{
				listItem:
					"Business Transfers. In the event that Ons creates, merges with, or is acquired by another entity, your information will most likely be transferred. Ons will email you or place a prominent notice on our website before your information becomes subject to another privacy policy.",
			},
			{
				listItem:
					"Protection of Ons and others. We release personal information when we believe release is appropriate to comply with the law, enforce or apply our Terms and conditions and other agreements, or protect the rights, property, or safety of Ons, our users or others. This includes exchanging information with other companies and organisations for fraud protection and credit risk reduction.",
			},
			{
				listItem:
					"With Your Consent. Other than as set out above, you will receive notice when personally identifiable information about you might go to third parties, and you will have an opportunity to choose not to share the information.",
			},
			{
				listItem:
					"Email Address. The email address that you supply to us for purposes of receiving our email communications will never be rented or sold to a third party.",
			},
		],
	},
	{
		number: "7.",
		question: "PROTECTING YOUR INFORMATION",
		Answer: [
			{
				paragraph:
					"We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you provide through the platform. If transactions are processed on the platform, transaction information is transmitted to and from the platform in encrypted form using industry-standard SSL connections to help protect such information from interception. We restrict authorised access to your personal information to those persons who have a legitimate purpose to know that information and to those persons you have authorised to have access to that information. Ons follows generally accepted industry standards to protect the personal information submitted to us, both during transmission and once Ons receives it. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while Ons strives to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. We will not sell, distribute, or lease your personal information to third parties unless we have your permission or are required by law to do so.",
			},
		],
		List: [],
	},

	{
		number: "8.",
		question: "RIGHTS ",
		Answer: [
			{
				paragraph:
					"Users or owners of the personal information they provide through the platform and website may exercise these rights over their personal information at any time and without any limitation by sending us their request through our contact information.",
			},
			{
				paragraph:
					"Users who provide information through our website and platform, as data subjects and data owners, have the right to access, rectify, download or delete their information, as well as to restrict and object to certain processing of their information. While some of these rights apply generally, others apply only in certain limited circumstances. We describe these rights below:",
			},
		],
		List: [
			{
				listItem:
					"Access and portability: to access and know what information is stored in our servers, you can send us your request through our contact information.",
			},
			{
				listItem:
					"Rectify, Restrict, Limit and/or Delete: You can also rectify, restrict, limit or delete much of your information.",
			},
			{
				listItem:
					"Right to be informed: Users of our platform will be informed, upon request, about what data we collect, how it is used, how long it is retained and whether it is shared with third parties.",
			},
			{
				listItem:
					"Object: When we process your information based on our legitimate interests as explained above, or in the public interest, you may object to this processing in certain circumstances. In such cases, we will stop processing your information unless we have compelling legitimate reasons to continue processing it or where it is necessary for legal reasons.",
			},
			{
				listItem:
					"Revoke consent: Where you have previously given your consent, such as to allow us to process and store your personal information, you have the right to revoke your consent to the processing and storage of your information at any time. For example, you may withdraw your consent by updating your settings. In certain cases, we may continue to process your information after you have withdrawn your consent if we have a legal basis for doing so or if your withdrawal of consent was limited to certain processing activities.",
			},
			{
				listItem:
					"Complaint: If you wish to file a complaint about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority. Users can exercise all these rights by contacting us through the contact information or the contact page.",
			},
			{
				listItem:
					"Rights related to automated decision-making, including profiling: Platform users may request that we provide a copy of the automated processing activities we conduct if they believe that data is being unlawfully processed.",
			},
		],
	},

	{
		number: "9.",
		question: "CHILDREN’S ONLINE PRIVACY PROTECTION ",
		Answer: [
			{
				paragraph:
					"We comply with the requirements of the Data Protection Act 2018 (UK regulations) and the GDPR (General Data Protection Regulation - European regulations), regarding the protection of personal data of minors. We do not collect any information from persons under the age of 18. Our platform and services are intended for users over the age of 18. If we become aware that a person under the age of 18 has provided us with personal information, we will take steps to remove such information, restrict access to the platform and terminate the account of such person under the age of 18.",
			},
		],
		List: [],
	},

	{
		number: "10.",
		question: "THIRD PARTIES",
		Answer: [
			{
				paragraph:
					"Except as otherwise expressly included in this Privacy Policy, this document addresses only the use and disclosure of information Ons collects from you. If you disclose your information to others, whether other Ons users or vendors, different rules may apply to their use or disclosure of the information you disclose to them. Ons does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. Ons is not responsible for the privacy or security practices of other websites, including those that are linked to from Ons.",
			},
		],
		List: [],
	},

	{
		number: "11.",
		question: "CONTACT US ",
		Answer: [
			{
				paragraph:
					"If you have any questions or concerns about this privacy policy and the processing and security of your data, please contact us via our contact page or by using the following contact information:",
			},
			{
				paragraph: "Valos IT LTD - Ons.",
			},
		],
		List: [
			{
				listItem: "Email : ons-support@valosit.co.uk",
			},
		],
	},
];

export const TermConditionData = [
	{
		number: "1.",
		question: "ACCEPTANCE OF TERMS",
		Answer: [
			{
				paragraph:
					"This agreement sets forth legally binding terms for your use of Ons. By registering and using the platform, you agree to be bound by this agreement. If you do not accept the terms of this agreement, you should not use the platform and discontinue use of the service immediately. We may modify this agreement from time to time, and such modification shall be effective upon its posting on the platform. You agree to be bound by any modification to these terms and conditions when you use Ons after any such modification is posted; it is therefore important that you review this agreement regularly.",
			},
			{
				paragraph:
					"You represent and warrant that all registration information you submit is accurate and truthful; and that your use of the platform does not violate any applicable law or regulation. Ons may, in its sole discretion, refuse to offer the services to any user and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the service and the platform is revoked in such jurisdictions.",
			},
			{
				paragraph:
					"You represent and warrant that all registration information you submit is accurate and truthful; and that your use of the platform does not violate any applicable law or regulation. Ons may, in its sole discretion, refuse to offer the services to any user and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the service and the platform is revoked in such jurisdictions.",
			},
		],
		List: [],
	},
	{
		number: "2.",
		question: "AGE RESTRICTIONS",
		Answer: [
			{
				paragraph:
					"Our platform is intended for users over 18 years of age. Ons reserves the right to make any necessary checks during registration to verify the age of the user. Ons reserves the right to suspend or terminate access to the platform to any user under the age of 18.",
			},
		],
		List: [],
	},
	{
		number: "3.",
		question: "ACCOUNT",
		Answer: [
			{
				paragraph:
					"Once provided, users must provide a valid telephone number for authentication and user registration purposes. Once the user registers, the user will be able to log in through a verification code that will be sent to the user's phone number. You are responsible for maintaining the confidentiality of your account information and are fully responsible for all activities that occur under your account. You agree to (a) immediately notify Ons of any unauthorized use of your account or any other breach of security, and (b) ensure that you log out of your account at the end of each session. You may never use another user's account without Ons' prior authorization. Ons will not be liable for any loss or damage arising from your failure to comply with this agreement.",
			},
			{
				paragraph:
					"Users may cancel their accounts at any time and for any reason by following the instructions on the platform or by sending us their request through our contact information. That termination will only result in the deletion of the account and the deletion of all the personal data granted to Ons. ",
			},
			{
				paragraph:
					"Ons reserves the right to terminate your account or your access immediately, with or without notice to you, and without liability to you, if Ons believes that you have breached any of these terms, furnished Ons with false or misleading information, or interfered with use of the platform or the service by others.",
			},
			{
				paragraph:
					"In order to use the platform, users must register on the platform and provide the following data during the registration process: ",
			},
		],
		List: [
			{
				listItem: "Username",
			},
			{
				listItem: "User birthday",
			},
			{
				listItem: "Gender",
			},
			{
				listItem: "Relationship status",
			},
			{
				listItem: "Sexual orientation",
			},
		],
	},
	{
		number: "4.",
		question: "NOTIFICATIONS AND NEWSLETTER",
		Answer: [
			{
				paragraph:
					"By providing Ons with your email address, you agree that we may use your email address to send you notices about Ons. We may also use your email address to send you notifications and other messages, such as changes to service features, news and special content. If you do not wish to receive these emails, you may opt-out of receiving them by sending us your removal request through the contact information or by using the unsubscribe option in the emails. Opting out may prevent you from receiving notifications and mailings about updates, news or special content.",
			},
		],
		List: [],
	},
	{
		number: "5.",
		question: "POINTS",
		Answer: [
			{
				paragraph:
					"For the purpose of using the platform, users will be able to acquire points that will allow them to be visible on the map in other users' searches according to the interest selected by the user (animal icon) and for a certain amount of time. The user will be able to select the amount of time their user will be visible in other users' searches and use the points according to the amount of time selected by the user. Searches will be available by region, sub-region and worldwide.",
			},
			{
				paragraph:
					"Once users acquire points, they will be able to use them immediately, selecting the amount of time and the animal icon according to the user's type of interest. The user's icon will be visible to other users for the amount of time selected by the user and according to the user's geographical location on the map available on the platform. Once the time selected by the user expires, the user's icon will no longer be visible to other users on the map.",
			},
			{
				paragraph: "There will be 4 types of interest or animal icon available on the platform:",
			},
		],
		List: [],
	},
	{
		number: "6.",
		question: "PAYMENTS",
		Answer: [
			{
				paragraph:
					"The price of the points will depend on the amount of points purchased by the user. The payment of the points will be charged to your credit/debit card immediately after completing the payment process. The points will be credited to each user's account and can be used immediately for the purpose of making the user visible in other users' searches on the platform. Once the transaction is processed, we will send an electronic receipt to the user's email address. ",
			},
			{
				paragraph:
					"If you find any inconsistencies in your billing, please contact us via our contact details or you can make a complaint via the Buyer service of the relevant payment platform.",
			},
			{
				paragraph:
					"If your card is declined, you will receive an error message. No payment will be charged to your card and no order will be processed. There may be a pending transaction on your account until your card issuing bank withdraws the authorisation. This usually takes 2 to 5 working days. Your card may be declined for a number of reasons, such as insufficient funds, AVS (Address Verification System) mismatch or you have entered an incorrect security code.",
			},
			{
				paragraph:
					"If your payment is declined, you will need to provide an alternative payment method or provide another card on which the payment can be charged and processed.",
			},
			{
				paragraph:
					"Your payment details will be processed and stored securely and solely for the purpose of processing the purchase of points. Ons reserves the right to contract any payment platform available on the market, which processes your data for the sole purpose of processing the purchase of points.",
			},
			{
				paragraph: "Points can be paid through the following processors and payment methods: ",
			},
		],
		List: [
			{
				listItem: "Credit/debit card (Stripe)",
			},
			{
				listItem: "Google Pay",
			},
			{
				listItem: "Apple Pay",
			},
		],
	},
	{
		number: "7.",
		question: "SERVICE RULES",
		Answer: [
			{
				paragraph:
					"Ons reserves the right to terminate the accounts and profiles of users who have violated these terms and conditions, at its sole discretion and without prior notice.",
			},

			{
				paragraph:
					"The use of the platform and the communications established between users within the platform must comply with the following rules and prohibitions:",
			},
		],
		List: [
			{
				listItem: "Members must be 18 years of age or older.",
			},
			{
				listItem: "It is forbidden to provide false data or false identities.",
			},
			{
				listItem: "It is forbidden to use another user's account.",
			},
			{
				listItem: "Any kind of discrimination is prohibited.",
			},
			{
				listItem: "Soliciting money, loans, gifts or any form of compensation.",
			},
			{
				listItem: "Disseminate another person's personal information without their permission.",
			},
			{
				listItem: "Use the platform in connection with any unlawful service.",
			},
			{
				listItem: "Use the platform to defraud, commit fraud and induce other users to cheat.",
			},
			{
				listItem: "Use the platform to perform pyramid schemes.",
			},
			{
				listItem:
					"Soliciting passwords for any purpose, or personally identifiable information for commercial or unlawful purposes.",
			},
			{
				listItem: "Public places are highly recommended.",
			},
			{
				listItem: "Harassment is totally prohibited.",
			},
			{
				listItem: "Offensive, inappropriate, hateful, threatening and obscene language is prohibited.",
			},
		],
	},

	{
		number: "8.",
		question: "REPORT PROFILE FUNCTION",
		Answer: [
			{
				paragraph:
					"Users may use the Report Profile function available on each user's profile, if the user believes that the reported user is in violation of the rules and terms and conditions described in this document. Ons will evaluate the reports and make a decision based on the violations committed by the reported user. The Report Profile feature helps to create a safe and secure environment for users and should be used with full responsibility and restraint.",
			},
			{
				paragraph:
					"Users will also be able to block or delete any user. The option to block or delete is available in each user's profile. Blocked users will no longer be able to access or communicate with that user's profile and deleted users will no longer appear in the conversation list, although they will be able to communicate with the user.",
			},
		],
		List: [],
	},

	{
		number: "9.",
		question: "DISCLAIMER",
		Answer: [
			{
				paragraph:
					"By using the platform and the functionalities included in it, you accept personal responsibility for the results of the use of the platform. You agree that Ons does not guarantee any outcome, negative or positive as a result of the use of our platform. You acknowledge and agree that your ultimate success or failure to use the platform and the features available on the platform will be the result of your own actions, your particular situation and other circumstances beyond Ons's control.",
			},
			{
				paragraph:
					"Ons is not responsible for damage to the physical or moral integrity of persons, such as injury, death or any other moral damage such as threats, insults and slander that may be caused to a natural person as a result of communications established through the platform or website. Communications and relationships established between users as a result of any connection within the platform or the website are the sole responsibility of the users.",
			},
			{
				paragraph:
					"In the event that one or more users or any third party initiates any type of claim or legal action against another or other users, each and every one of the users involved in such claims or actions exempt Ons from any liability.",
			},
		],
		List: [],
	},

	{
		number: "10.",
		question: "THIRD-PARTY MATERIALS",
		Answer: [
			{
				paragraph:
					"Third-Party Materials means any content, images, videos, texts or other material that is owned by a third party, such as stock images, videos and texts. Such Third-Party Materials are subject to the applicable third-party terms and licenses, and may only be used as permitted by such terms and licenses.",
			},
		],
		List: [],
	},

	{
		number: "11.",
		question: "LICENSE TO USE THE PLATFORM",
		Answer: [
			{
				paragraph:
					"Ons gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to you by Ons as part of the services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the services as provided by Ons, in the manner permitted by these terms. You may not copy, modify, distribute, sell, or lease any part of our services or included software, nor may you reverse engineer or attempt to extract the source code of that software, unless laws prohibit those restrictions, or you have our written permission.",
			},
			{
				paragraph:
					"The user agrees not to use the platform and the services negligently, for fraudulent purposes or in an unlawful manner. Likewise, the user agrees not to partake in any conduct or action that could damage the image, interests, or rights of the Ons platform or third parties.",
			},
			{
				paragraph:
					"This service prohibits the sending of messages, which: (1) Any type of messages that are categorised as SPAM. (2) Are harassing, abusive, defamatory, obscene, in bad faith, unethical or illegal content (3) Distribute Trojan horses, viruses or other malicious computer software (4) Any message with political or religious content (5) Any message with obscene or offensive content (6) Are intended to commit fraud, impersonation of others, (7) distribute intellectual property without owning or having a license to distribute such property (8) violate, in any way, the terms of service, privacy policy or rules of this website or the recipients.",
			},
			{
				paragraph:
					"Ons reserves the right to terminate your access immediately, with or without notice, and without liability to you, if Ons believes that you have violated any of these terms or interfered with the use of the platform or service by others.",
			},
		],
		List: [],
	},

	{
		number: "12.",
		question: "COPYRIGHT",
		Answer: [
			{
				paragraph:
					"All materials on Ons, including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos, photographs, illustrations, software and other elements are protected by copyrights, trademarks and/or other intellectual property rights owned and controlled by Ons or by third parties that have licensed or otherwise provided their material to the platform. You acknowledge and agree that all Materials on Ons are made available for limited, non-commercial, personal use only. Except as specifically provided herein. No material may be copied, reproduced, republished, sold, downloaded, posted, transmitted, or distributed in any way, or otherwise used for any purpose, by any person or entity, without Ons prior express written permission. You may not add, delete, distort, or otherwise modify the material. Any unauthorised attempt to modify any material, to defeat or circumvent any security features, or to utilise Ons or any part of the material for any purpose other than its intended purposes is strictly prohibited.",
			},
		],
		List: [],
	},

	{
		number: "13.",
		question: "COPYRIGHT INFRINGEMENT ",
		Answer: [
			{
				paragraph:
					"Ons will respond to all enquiries, complaints and claims relating to alleged infringement by breach or violation of the provisions contained in UK and international copyright and intellectual property laws and regulations. Ons respects the intellectual property of others and expects users to do the same. If you believe, in good faith, that any material provided on the Ons website infringes your copyright or other intellectual property rights, please submit your request via our contact information, with the following information:  ",
			},
		],
		List: [
			{
				listItem:
					"Identification of the intellectual property right that is allegedly infringed. All relevant registration numbers or a statement regarding ownership of the work should be included.",
			},
			{
				listItem:
					"A statement that specifically identifies the location of the infringing material, in sufficient detail so that Ons can find it on the platform. ",
			},
			{
				listItem: "Your name, address, telephone number and email address.",
			},
			{
				listItem:
					"A statement by you that you have a good faith belief that the use of the allegedly infringing material is not authorised by the copyright owner, or its agents, or by law.",
			},
			{
				listItem:
					"A statement by you, made under penalty of perjury, that the information in your notification is accurate, and that you are the copyright owner or authorised to act on its behalf.",
			},
			{
				listItem:
					"An electronic or physical signature of the copyright owner or of the person authorised to act on the copyright owners behalf.",
			},
		],
	},

	{
		number: "14.",
		question: "USER CONTENT ",
		Answer: [
			{
				paragraph:
					"Some features of the platform may allow users to provide information and content. You retain all rights you may have in the content and information you provide through the platform. Ons is not responsible for the accuracy, security or legality of the information provided by the user through the platform. You are solely and exclusively responsible for your content and information. By providing content and information through the platform, user grants Ons a worldwide, non-exclusive, royalty-free, fully paid-up right and license to host, store, transfer, display, perform, reproduce and modify user content for the sole purpose of providing the services and functionalities available on the platform.",
			},
		],
		List: [],
	},

	{
		number: "15.",
		question: "REPRESENTATIONS AND WARRANTIES FOR USER CONTENT",
		Answer: [
			{
				paragraph:
					"Ons disclaims all liability in connection with the user content. You are solely and exclusively responsible for your content and the consequences of providing content through the platform. By providing and uploading content through the platform, you affirm, represent and warrant that:",
			},
		],
		List: [
			{
				listItem:
					"You are the owner of the content you provide through the platform, or have the necessary licenses, rights, consents and permissions to authorise Ons to publish and display your content through the platform.",
			},
			{
				listItem:
					"Your content, as contemplated by these terms, does not infringe, violate or misappropriate any third party right, including any copyright, trademark, patent, trade secret, moral right, right of privacy, right of publicity or any other intellectual property or proprietary right; (ii) libels, defames, slanders or invades the right of privacy, publicity or other proprietary rights of any other person; or (iii) causes Ons to violate any law or regulation.",
			},
			{
				listItem:
					"Your content could not be considered by a reasonable person to be objectionable, profane, indecent, pornographic, harassing, threatening, embarrassing, hateful or otherwise inappropriate.",
			},
			{
				listItem:
					"Your content does not and will not contain hateful content, threat of physical harm or harassment.",
			},
		],
	},

	{
		number: "16.",
		question: "DISCLAIMER OF LIABILITY FOR USER CONTENT",
		Answer: [
			{
				paragraph:
					"Ons may, at any time and without notice, filter, remove, edit or block any user content that, in our sole judgment, violates these terms or is otherwise objectionable. If a user or content owner notifies us that user content allegedly does not conform to these terms, we may investigate the allegation and determine in our sole discretion whether to remove the user content or restrict the offending users account, which we reserve the right to do at any time and without notice. You acknowledge and agree that Ons reserves the right to, and may from time to time, monitor any and all information transmitted or received through the platform for Ons's operational and other legitimate purposes. If at any time Ons decides to monitor content, Ons continues to assume no responsibility or liability for the content or any loss or damage incurred as a result of the use of the content. During monitoring, information may be examined, recorded, copied and used in accordance with our privacy policy.",
			},
		],
		List: [],
	},

	{
		number: "17.",
		question: "PERSONAL DATA",
		Answer: [
			{
				paragraph:
					"Any personal information you submit in connection with the use of the platform will be used in accordance with our privacy policy. Please see our privacy policy.",
			},
		],
		List: [],
	},

	{
		number: "18.",
		question: "CONFIDENTIAL INFORMATION",
		Answer: [
			{
				paragraph:
					"Information provided by our users through the use of the platform will be treated as confidential information and will be used solely for the purpose of providing the functionalities within the platform. Ons will protect such Confidential Information from disclosure to third parties by employing the same degree of care used to protect its own confidentiality or proprietary information of similar importance. If necessary to conduct a prospective business relationship, Ons may disclose Confidential Information received pursuant to our services to our employees who have a need to know and a legitimate interest, provided that the employees are obligated to protect such Confidential Information from unauthorised use and disclosure. Confidential information will not be disclosed to third parties without the prior written consent of the user who owns the information.",
			},
		],
		List: [],
	},

	{
		number: "19.",
		question: "PROHIBITED ACTIVITIES",
		Answer: [
			{
				paragraph:
					"The content and information available on the platform (including, but not limited to, data, information, text, music, sound, photos, graphics, video, maps, icons or other material), as well as the infrastructure used to provide such content and information, is proprietary to Ons or licensed to the Ons by third parties. For all content other than your content, you agree not to otherwise modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, software or services obtained from or through the platform. In addition, the following activities are prohibited:",
			},
		],
		List: [
			{
				listItem:
					"Use the services or content for any commercial purpose, outside the scope of those commercial purposes explicitly permitted under this agreement and related guidelines as made available by Ons.",
			},
			{
				listItem:
					"Access, monitor, reproduce, distribute, transmit, broadcast, display, sell, license, copy or otherwise exploit any content of the services, including but not limited to, using any robot, spider, scraper or other automated means or any manual process for any purpose not in accordance with this agreement or without our express written permission.",
			},
			{
				listItem:
					"Violate the restrictions in any robot exclusion headers on the services or bypass or circumvent other measures employed to prevent or limit access to the services.",
			},
			{
				listItem:
					"Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure.",
			},
			{
				listItem:
					"Deep-link to any portion of the services for any purpose without our express written permission.",
			},
			{
				listItem:
					"Frame, mirror or otherwise incorporate any part of the services into any other websites or service without our prior written authorisation.",
			},
			{
				listItem:
					"Attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by Ons in connection with the services.",
			},
			{
				listItem:
					"Circumvent, disable or otherwise interfere with security-related features of the services or features that prevent or restrict use or copying of any content.",
			},
		],
	},

	{
		number: "20.",
		question: "DISCLAIMER OF WARRANTIES ",
		Answer: [
			{
				paragraph:
					"Ons will provide its services with reasonable skill and care but does not give any guarantees, warranties or representations in respect of any other person's services.",
			},
			{
				paragraph:
					"Because of the nature of the Internet Ons provides and maintains the platform on an as is, as available basis and makes no promise that use of the platform will be uninterrupted or entirely error free. We are not responsible to you if we are unable to provide our Internet services for any reason beyond our control.",
			},
			{
				paragraph:
					"Our platform may from time to time contain links to other websites which are not under the control of and are not maintained by us. These links are provided for your convenience only and we are not responsible for the content of those websites.",
			},
			{
				paragraph:
					"Except as provided above we can give no other warranties, conditions or other terms, express or implied, statutory or otherwise and all such terms are hereby excluded to the maximum extent permitted by law.",
			},
			{
				paragraph:
					"You will be responsible for any breach of these terms by you and if you use the platform in breach of these terms you will be liable to and will reimburse Ons for any loss or damage caused as a result.",
			},
			{
				paragraph:
					"Ons will not be liable in any amount for failure to perform any obligation under this agreement if such failure is caused by the occurrence of any unforeseen event beyond its reasonable control including without limitation Internet outages, communications outages, fire, flood, war or act of God.",
			},
			{
				paragraph: "These terms do not affect your statutory rights as a consumer which are available to you.",
			},
			{
				paragraph:
					"Subject as aforesaid, to the maximum extent permitted by law, Ons excludes liability for any loss or damage of any kind howsoever arising, including without limitation any direct, indirect or consequential loss whether or not such arises out of any problem you notify to Ons and Ons shall have no liability to pay any money by way of compensation, including without limitation all liability in relation to:",
			},
		],
		List: [
			{ listItem: "Any incorrect or inaccurate information on the platform." },
			{
				listItem:
					"The infringement by any person of any Intellectual Property Rights of any third party caused by their use of the platform.",
			},
			{
				listItem:
					"Any loss or damage resulting from your use or the inability to use the platform or resulting from unauthorised access to, or alteration of your transmissions or data in circumstances which are beyond our control.",
			},
			{
				listItem:
					"Any loss of profit, wasted expenditure, corruption or destruction of data or any other loss which does not directly result from something we have done wrong.",
			},
			{
				listItem:
					"Any amount or kind of loss or damage due to viruses or other malicious software that may infect a users computer equipment, software, data or other property caused by persons accessing or using content from the platform or from transmissions via emails or attachments received from Ons.",
			},
			{
				listItem:
					"All representations, warranties, conditions and other terms which but for this notice would have effect.",
			},
		],
	},

	{
		number: "21",
		question: "INDEMNIFICATION",
		Answer: [
			{
				paragraph:
					"You agree to defend and indemnify Ons from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:",
			},
		],
		List: [
			{
				listItem: "Your breach of this agreement or the documents referenced herein.",
			},
			{
				listItem: "Your violation of any law or the rights of a third party.",
			},
			{
				listItem: "Your use of the Ons platform.",
			},
		],
	},

	{
		number: "22.",
		question: "CHANGES AND TERMINATION",
		Answer: [
			{
				paragraph:
					"We may change the platform and these terms at any time, in our sole discretion and without notice to you. You are responsible for remaining knowledgeable about these terms. Your continued use of the platform constitutes your acceptance of any changes to these terms and any changes will supersede all previous versions of the terms. Unless otherwise specified herein, all changes to these terms apply to all users take effect. Furthermore, we may terminate this agreement with you under these terms at any time by notifying you in writing (including by email) or without any warning.",
			},
		],
		List: [],
	},

	{
		number: "23",
		question: "ASSIGNMENT",
		Answer: [
			{
				paragraph:
					"This agreement and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by Ons without restriction.",
			},
		],
		List: [],
	},

	{
		number: "24.",
		question: "INTEGRATION CLAUSE",
		Answer: [
			{
				paragraph:
					"This agreement together with the privacy policy and any other legal notices published by Ons, shall constitute the entire agreement between you and Ons concerning and governs your use of the platform.",
			},
		],
		List: [],
	},

	{
		number: "25.",
		question: "DISPUTES",
		Answer: [
			{
				paragraph:
					"The user agrees that any dispute, claim or controversy arising out of or relating to these terms and conditions, or the breach, termination, enforcement, interpretation or validity thereof or the use of the platform, shall be resolved by binding arbitration between the user and Ons, provided that each party retains the right to bring an individual action in a court of competent jurisdiction.",
			},
			{
				paragraph:
					"In the event that a dispute arises in connection with the use of the platform or breach of these terms and conditions, the parties agree to submit their dispute to arbitration resolution before a reputable arbitration organisation as mutually agreed by the parties and in accordance with applicable commercial arbitration rules.",
			},
			{
				paragraph:
					"You agree to initiate a formal dispute proceeding by sending us a communication through our contact information. Ons may choose to send you a written offer after receiving your initial communication. If we offer and send you a settlement offer and you do not accept the offer, or we are unable to resolve your dispute satisfactorily and you wish to continue with the dispute process, you must initiate the dispute resolution process before an accredited arbitration organisation and file a separate Demand for Arbitration. Any award rendered by the arbitration tribunal shall be final and conclusive on the parties. ",
			},
			{
				paragraph:
					"To the fullest extent permitted by law, you agree that you will not file, join or participate in any class action lawsuit in connection with any claim, dispute or controversy that may arise in connection with your use of the platform.",
			},
			{
				paragraph:
					"The courts of the United Kingdom shall have jurisdiction over any dispute, controversy or claim relating to Ons and its business operations. Any such dispute or controversy shall be brought and resolved in the courts of the United Kingdom.",
			},
		],
		List: [],
	},

	{
		number: "26.",
		question: "FINAL PROVISIONS",
		Answer: [
			{
				paragraph:
					"These terms and conditions are governed by the laws of the United Kingdom. Use of the Ons platform is unauthorised in any jurisdiction that does not give effect to all provisions of these terms and conditions.",
			},
			{
				paragraph:
					"Our performance of these terms is subject to existing laws and legal process, and nothing contained in these Terms limits our right to comply with law enforcement or other governmental or legal requests or requirements relating to your use of our platform or information provided to or gathered by us with respect to such use.",
			},
			{
				paragraph:
					"If any part of these terms is found to be invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions will not in any way be affected or impaired. Our failure or delay in enforcing any provision of these Terms at any time does not waive our right to enforce the same or any other provision(s) hereof in the future.",
			},
			{
				paragraph: "Any rights not expressly granted herein are reserved.",
			},
		],
		List: [],
	},

	{
		number: "27. ",
		question: "CONTACT INFORMATION",
		Answer: [
			{
				paragraph:
					"If you have questions or concerns about these terms, please contact us through our contact page or via the contact information below:",
			},
			{
				paragraph: "Valos IT LTD - Ons.",
			},
		],
		List: [
			{
				listItem: "Email : ons-support@valosit.co.uk",
			}
		],
	},
];
