import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";

// components
import DownloadSection from "../home/Download";

// images
import ImageProp from "../../assets/mobileProp/Frame520-min.png";
import EmailIconImage from "../../assets/icons/Mail-min.png";
import FacebookIcon from "../../assets/icons/Facebook-min.png";
import AppStoreIcon from "../../assets/icons/AppSymbol-min.png";
import InstagramIcon from "../../assets/icons/Instagram-min.png";
import TwitterIcon from "../../assets/icons/Twitter-min.png";
import PlayStoreIcon from "../../assets/icons/Playstore-min.png";

// styled
const Container = styled.section`
	padding-block: 5rem;

	@media (min-width: 601px) and (max-width: 1024px) {
		padding-block: 1rem;
	}
`;

const Wrapper = styled.div`
	width: 80%;
	margin: auto;

	@media (max-width: 600px) {
		width: 95%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const ImageWrapper = styled.div`
	text-align: center;
	overflow: hidden;

	@media (max-width: 600px) {
		margin-top: 4rem;
	}
`;

const Image = styled.img`
	height: 100%;
	width: auto;
	object-fit: contain;

	@media (max-width: 600px) {
		width: auto;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 15rem;
	}
`;

const ContactWrapper = styled.div``;

const EmailBox = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;

	@media (max-width: 600px) {
		justify-content: center;
		text-align: center;
	}
`;

const EmailIcon = styled.img`
	width: 4rem;

	@media (max-width: 600px) {
		width: 3rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 2rem;
	}
`;

const EmailText = styled.a`
	color: var(--blackColor);
	font-size: 1.3rem;
	font-weight: 600;
	letter-spacing: 1px;
	text-decoration: none;
	opacity: 0.8;

	@media (max-width: 600px) {
		font-size: 1.1rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.7rem;
	}
`;

const SocialMediaWrapper = styled.div`
	margin-top: 6rem;

	@media (max-width: 600px) {
		margin-top: 4rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		margin-top: 3rem;
	}
`;

const SocialMediaHeaderText = styled.h3`
	font-size: 2rem;
	font-weight: 700;
	letter-spacing: 0.5px;
	line-height: 1.5;

	@media (max-width: 600px) {
		font-size: 1.8rem;
		text-align: center;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.9rem;
	}
`;

const HeaderColor = styled.span`
	color: var(--MainColor);
`;

const SocialMediaBox = styled.div`
	display: flex;
	gap: 1rem;
	margin-top: 2rem;

	@media (max-width: 600px) {
		justify-content: center;
		margin-top: 4rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		gap: 0.5rem;
		margin-top: 1rem;
	}
`;

const SocialMediaIcon = styled.img`
	width: 3rem;

	@media (min-width: 601px) and (max-width: 1024px) {
		width: 1.5rem;
	}
	&:hover {
		transform: scale(1.1);
		transition: all 0.2s ease-in-out;
	}
`;

export default function Details() {
	return (
		<>
			<Container>
				<Wrapper>
					<Grid container justifyContent='space-around' alignItems='center'>
						<Grid item xs={12} sm={6} md={6}>
							<ContactWrapper>
								<EmailBox>
									<EmailIcon src={EmailIconImage} alt='Ons Email Icon' loading='lazy' />

									<EmailText href='mailto:ons-support@valosit.co.uk'>ons-support@valosit.co.uk</EmailText>
								</EmailBox>
								{/* Social Media Icon */}
								<SocialMediaWrapper>
									<SocialMediaHeaderText>
										Connect With Us On <HeaderColor>Social Media</HeaderColor>
									</SocialMediaHeaderText>
									<SocialMediaBox>
										<a href='/' target='_blank' rel='noopener noreferrer'>
											<SocialMediaIcon src={FacebookIcon} alt='Ons Social Media' />
										</a>
										<a href='/' target='_blank' rel='noopener noreferrer'>
											<SocialMediaIcon src={InstagramIcon} alt='Ons Social Media' />
										</a>
										<a href='/' target='_blank' rel='noopener noreferrer'>
											<SocialMediaIcon src={TwitterIcon} alt='Ons Social Media' />
										</a>
										<a href='/' target='_blank' rel='noopener noreferrer'>
											<SocialMediaIcon src={AppStoreIcon} alt='Ons Social Media' />
										</a>
										<a href='/' target='_blank' rel='noopener noreferrer'>
											<SocialMediaIcon src={PlayStoreIcon} alt='Ons Social Media' />
										</a>
									</SocialMediaBox>
								</SocialMediaWrapper>
							</ContactWrapper>
						</Grid>

						<Grid item xs={12} sm={4} md={4}>
							<ImageWrapper>
								<Image src={ImageProp} alt='Say Hello to Ons!' />
							</ImageWrapper>
						</Grid>
					</Grid>
				</Wrapper>
			</Container>

			<DownloadSection />
		</>
	);
}
