import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Divider } from "@mui/material";

import EmailIcon from "../../assets/icons/footer-email.png";
import TwitterIcon from "../../assets/icons/footer-twitter.png";
import InstagramIcon from "../../assets/icons/footer-instagram.png";
import SnapIcon from "../../assets/icons/footer-snapchat.png";

// image
import OnsLogo from "../../assets/logo/logo.png";

// styled
const Container = styled.footer`
	padding: 4rem 0 2rem 0;
`;

const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 87%;
	margin: auto auto 2rem auto;

	@media (max-width: 600px) {
		width: 100%;
		flex-direction: column;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
		flex-direction: column;
	}
`;

const LogoWrapper = styled.div`
	display: flex;
	flex-grow: 1;
`;

const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;

	@media (max-width: 600px) {
		margin-bottom: 2rem;
	}
`;

const Logo = styled.a``;

const LogoImage = styled.img`
	width: 4rem;
	height: 100%;
	object-fit: cover;

	@media (max-width: 600px) {
		width: 5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 2rem;
	}
`;

const DesktopWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 5;

	@media (max-width: 600px) {
		flex-direction: column;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		flex-grow: 1;
	}
`;

const ButtonTheme = styled.button`
	font-family: "Montserrat";
	font-size: 0.8rem;
	font-weight: 500;
	background: transparent;
	border: none;
	border-radius: 0.2rem;
	cursor: pointer;
	letter-spacing: 0.5px;
	padding: 0.6rem 1rem;

	@media (max-width: 1024px) {
		padding: 1rem 0.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
	}
`;

const ButtonLink = styled.a`
	margin: 0 0.5rem;
	padding: 0.5rem 0;
	border-radius: 0.2rem;
`;

const ButtonLinkDiv = styled.div`
	margin: 0 0.5rem;
	padding: 0.5rem 0;
	border-radius: 0.2rem;
`;

const DesktopButton = styled(ButtonTheme)`
	color: var(--blackColor);
	background: transparent;
	opacity: 0.75;

	&:hover {
		color: var(--whiteColor);
		background-color: var(--ButtonHoverColor);
		opacity: 1;
		transition: all 0.2s ease-in-out;
	}
`;

const CtaWrapper = styled.div`
	display: flex;
	justify-content: center;

	@media (max-width: 600px) {
		margin-bottom: 2rem;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;

const ButtonATag = styled.a`
	color: var(--black);
`;

const CTAButtonTheme = styled.button`
	/* display */
	display: flex;
	align-items: center;
	/* color */
	color: var(--black);
	background: transparent;
	/* font */
	font-family: "Montserrat";
	font-size: 0.8rem;
	font-weight: 500;
	/* attr */
	border: none;
	border-radius: 1.5rem;
	letter-spacing: 0.5px;
	cursor: pointer;
	padding: 0.6rem;
	opacity: 0.75;

	@media (max-width: 600px) {
		font-size: var(--MobileFontSizeSmall);
		margin-block: 1rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
		padding: 0.8rem 2rem;
	}
	&:hover {
		background: var(--blackColor);
		color: var(--whiteColor);
		opacity: 1;
		transform: scale(1.03);
		transition: 0.3s all ease-in-out;
	}
`;

// social Media
const SocialMediaBox = styled.div`
	display: flex;
	justify-content: center;
	gap: 2rem;
	padding-block: 2rem;

	@media (max-width: 600px) {
		justify-content: center;
		gap: 1rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		gap: 1rem;
		padding-block: 1rem;
	}
`;

const SocialMediaIcon = styled.img`
	width: 2.5rem;

	@media (min-width: 601px) and (max-width: 1024px) {
		width: 1.5rem;
	}
	&:hover {
		transform: scale(1.1);
		transition: all 0.2s ease-in-out;
	}
`;

// copyrights
const CopyrightWrapper = styled.div`
	text-align: center;
	margin-top: 2rem;
`;

const CopyrightText = styled.p`
	font-size: var(--PcFontSizeSmall);
	opacity: 0.8;

	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
	}
`;

export default function Footer() {
	const [year, setYear] = useState("");
	// Retrieve Year function
	const YearHandler = () => {
		const yearValue = new Date().getFullYear();
		setYear(yearValue);
	};

	useEffect(() => {
		YearHandler();
	}, []);

	return (
		<Container>
			<Wrapper>
				<LogoWrapper>
					<Logo href='/'>
						<ImageWrapper>
							<LogoImage src={OnsLogo} alt='Ons Image' loading='lazy' />
						</ImageWrapper>
					</Logo>
				</LogoWrapper>

				<DesktopWrapper>
					<ButtonLinkDiv>
						<ButtonLink href='/'>
							<DesktopButton>Home</DesktopButton>
						</ButtonLink>
					</ButtonLinkDiv>

					<ButtonLinkDiv>
						<a href='#about_Section'>
							<DesktopButton>About</DesktopButton>
						</a>
					</ButtonLinkDiv>

					<ButtonLinkDiv>
						<a href='#download_Section'>
							<DesktopButton>Download</DesktopButton>
						</a>
					</ButtonLinkDiv>

					<ButtonLinkDiv>
						<a href='#contact_Section'>
							<DesktopButton>Learn</DesktopButton>
						</a>
					</ButtonLinkDiv>
				</DesktopWrapper>

				{/*  Download Button */}
				<CtaWrapper>
					<ButtonATag href='/term-and-conditions'>
						<CTAButtonTheme>Terms & Conditions</CTAButtonTheme>
					</ButtonATag>
					<ButtonATag href='/privacy-policy'>
						<CTAButtonTheme>Privacy Policy</CTAButtonTheme>
					</ButtonATag>
					<ButtonATag href='/cookies-policy'>
						<CTAButtonTheme>Cookies Policy</CTAButtonTheme>
					</ButtonATag>
				</CtaWrapper>
			</Wrapper>
			{/* Social Media */}
			<Divider sx={{ width: "80%", margin: "auto" }} />

			<SocialMediaBox>
				<a href='/' target='_blank' rel='noopener noreferrer'>
					<SocialMediaIcon src={EmailIcon} alt='Ons Social Media' />
				</a>
				<a href='/' target='_blank' rel='noopener noreferrer'>
					<SocialMediaIcon src={TwitterIcon} alt='Ons Social Media' />
				</a>
				<a href='/' target='_blank' rel='noopener noreferrer'>
					<SocialMediaIcon src={InstagramIcon} alt='Ons Social Media' />
				</a>
				<a href='/' target='_blank' rel='noopener noreferrer'>
					<SocialMediaIcon src={SnapIcon} alt='Ons Social Media' />
				</a>
			</SocialMediaBox>

			<Divider sx={{ width: "80%", margin: "auto" }} />

			<CopyrightWrapper>
				<CopyrightText>&copy;All Copyrights & Rights Reserved by Ons {year}</CopyrightText>
			</CopyrightWrapper>
		</Container>
	);
}
