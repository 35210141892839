import React, { useState } from "react";
import styled from "styled-components";
import { AppBar, Toolbar } from "@mui/material";

// shared components
import SwipeableDrawerMobile from "./SwipeableDrawerMobile";

// icons
import MenuIcon from "@mui/icons-material/Menu";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// image
import OnsLogo from "../../assets/logo/logo.png";

//Styled component
// styles
const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 87%;
	margin: auto;

	@media (max-width: 600px) {
		width: 100%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const LogoWrapper = styled.div`
	display: flex;
	flex-grow: 1;
`;

const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const Logo = styled.a``;

const LogoImage = styled.img`
	width: 3rem;
	height: 100%;
	object-fit: cover;

	@media (max-width: 600px) {
		width: 2.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 2rem;
	}
`;

const DesktopWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 5;

	@media (max-width: 1024px) {
		display: none;
	}
`;

const ButtonTheme = styled.button`
	font-family: "Montserrat";
	font-size: 0.9rem;
	font-weight: 500;
	background: transparent;
	border: none;
	border-radius: 0.2rem;
	cursor: pointer;
	letter-spacing: 0.5px;
	padding: 0.6rem 1rem;
`;

const ButtonLink = styled.a`
	margin: 0 0.5rem;
	padding: 0.5rem 0;
	border-radius: 0.2rem;
`;

const ButtonLinkDiv = styled.div`
	margin: 0 0.5rem;
	padding: 0.5rem 0;
	border-radius: 0.2rem;
`;

const DesktopButton = styled(ButtonTheme)`
	color: var(--blackColor);
	background: transparent;
	opacity: 0.75;

	&:hover {
		color: var(--whiteColor);
		background-color: var(--ButtonHoverColor);
		opacity: 1;
		transition: all 0.2s ease-in-out;
	}
`;

const CtaWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 0;
	gap: 0.5rem;

	@media (max-width: 1024px) {
		display: none;
	}
`;

const CTAButtonTheme = styled.button`
	/* display */
	display: flex;
	align-items: center;
	/* color */
	color: var(--whiteColor);
	background: linear-gradient(90deg, #dd2525 0%, #ff7a00 100%);
	/* font */
	font-family: "Montserrat";
	font-size: 0.9rem;
	font-weight: 500;
	/* attr */
	border: none;
	border-radius: 1.5rem;
	letter-spacing: 0.5px;
	cursor: pointer;
	padding: 0.8rem 1.5rem;

	@media (max-width: 600px) {
		font-size: var(--MobileFontSize);
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
		padding: 0.8rem 2rem;
	}
	&:hover {
		transform: scale(1.03);
		transition: 0.3s all ease-in-out;
	}
`;

// Mobile Style
const MobileWrapper = styled.div`
	display: none;
	@media (max-width: 1024px) {
		display: block;
	}
`;

export default function NavBar() {
	const [drawer, setDrawer] = useState(false);

	return (
		<AppBar
			position='fixed'
			elevation={4}
			sx={{
				// height: 64,
				background: "#ffffff",
			}}
		>
			<Toolbar>
				<Wrapper>
					<LogoWrapper>
						<Logo href='/'>
							<ImageWrapper>
								<LogoImage src={OnsLogo} alt='Ons Image' loading='lazy' />
							</ImageWrapper>
						</Logo>
					</LogoWrapper>

					<DesktopWrapper>
						<ButtonLinkDiv>
							<ButtonLink href='/'>
								<DesktopButton>Home</DesktopButton>
							</ButtonLink>
						</ButtonLinkDiv>

						<ButtonLinkDiv>
							<a href='#about_Section'>
								<DesktopButton>About</DesktopButton>
							</a>
						</ButtonLinkDiv>

						<ButtonLinkDiv>
							<a href='#download_Section'>
								<DesktopButton>Download</DesktopButton>
							</a>
						</ButtonLinkDiv>

						<ButtonLinkDiv>
							<a href='#contact_Section'>
								<DesktopButton>Contact</DesktopButton>
							</a>
						</ButtonLinkDiv>
					</DesktopWrapper>

					{/*  Download Button */}
					<CtaWrapper>
						<a href='#download_Section'>
							<CTAButtonTheme>
								Get Started
								<ArrowForwardIosIcon fontSize='10px' sx={{ marginLeft: "1rem" }} />
							</CTAButtonTheme>
						</a>
					</CtaWrapper>

					{/* Mobile Menu Button */}
					<MobileWrapper onClick={() => setDrawer(true)} style={{ cursor: "pointer" }}>
						<MenuIcon sx={{ fontSize: "2rem", color: "var(--MainColor)" }} />
					</MobileWrapper>
				</Wrapper>
				{/* Swipeable Drawer for Mobile and Tablet View */}
				<SwipeableDrawerMobile drawer={drawer} setDrawer={setDrawer} />
			</Toolbar>
		</AppBar>
	);
}
