import React from "react";
import styled from "styled-components";

// shared components
import OrangeButton from "../shared/OrangeButton";

// styled
const Container = styled.section`
	padding-block: 10rem;

	@media (max-width: 600px) {
		padding-block: 4rem;
	}
`;

const Wrapper = styled.div`
	width: 80%;
	margin: auto;

	@media (max-width: 600px) {
		width: 95%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const TextWrapper = styled.div`
	text-align: center;
`;

const Header = styled.h2`
	font-size: 3rem;
	font-weight: 800;
	letter-spacing: 0.5px;

	@media (max-width: 600px) {
		width: 95%;
		font-size: 2.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 2rem;
	}
`;

const HeaderColor = styled.span`
	color: var(--MainColor);
`;

const Description = styled.p`
	font-size: var(--PcFontSize);
	letter-spacing: 0.5px;
	opacity: 0.7;
	width: 70%;
	margin: 2rem auto 0 auto;
	line-height: 1.5;

	@media (max-width: 600px) {
		width: 95%;
		margin-top: 2rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSize);
		width: 70%;
	}
`;

const ButtonWrapper = styled.div`
	text-align: center;
	margin-top: 5rem;
`;

export default function Contact() {
	return (
		<Container id='contact_Section'>
			<Wrapper>
				<TextWrapper>
					<Header>
						<HeaderColor>Contact </HeaderColor>
						Us
					</Header>
					<Description>
						Have you got a topic you wish to discuss? We pledge to respond to your message or email as
						quickly as we can.
					</Description>

					<ButtonWrapper>
						<OrangeButton href='/contact'>Contact Us Now</OrangeButton>
					</ButtonWrapper>
				</TextWrapper>
			</Wrapper>
		</Container>
	);
}
