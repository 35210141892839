//import React, {useEffect} from "react";
import React from "react";
import styled from "styled-components";

import ImageProp from "../../assets/pattern/Group74-min.png";
import GoogleButton from "../../assets/downloadButton/googleplay-min.png";
import AppleButton from "../../assets/downloadButton/appstore-min.png";

// styles
const Container = styled.section`
	position: relative;
	z-index: 0;
	padding: 6rem 0;
	overflow: hidden;

	@media (max-width: 600px) {
		padding: 2rem 0 4rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 4rem 0;
	}
`;

const Image = styled.img`
	/* position */
	position: absolute;
	bottom: 0%;
	right: -10%;
	z-index: -1;
	/* size */
	height: 100%;
	width: auto;
	object-fit: contain;

	@media (max-width: 600px) {
		/* position: relative;
		width: 100%;
		margin: auto; */
		opacity: 0.2;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		opacity: 0.8;
	}
`;

const Wrapper = styled.div`
	width: 85%;
	margin: auto;

	@media (max-width: 600px) {
		width: 95%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const TextWrapper = styled.div`
	width: 55%;

	@media (max-width: 600px) {
		width: 100%;
		text-align: center;
		margin: 2rem auto 0 auto;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 50%;
	}
`;

const Header = styled.h2`
	font-size: 4rem;
	font-weight: 800;
	letter-spacing: 1px;
	line-height: 1.2;

	@media (max-width: 600px) {
		font-size: 2.8rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.8rem;
	}
`;

const HeaderColor = styled.span`
	color: var(--MainColor);
`;

const Description = styled.p`
	color: var(--lightDescriptionColor);
	font-size: var(--PcFontSize);
	margin-top: 2rem;
	font-weight: 400;
	opacity: 0.7;
	line-height: 1.5;
	letter-spacing: 0.5px;
	width: 90%;

	@media (max-width: 600px) {
		width: 100%;
		margin: 2rem auto 2rem auto;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
		width: 90%;
		margin-top: 1rem;
	}
`;

const ButtonWrapper = styled.div`
	/* display */
	display: flex;
	gap: 2rem;
	/* margin  */
	margin-top: 5rem;

	@media (max-width: 600px) {
		flex-direction: column;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		margin-top: 3rem;
		gap: 1rem;
	}
`;

const ButtonImage = styled.img`
	width: 12rem;
	border-radius: 0.5rem;
	box-shadow: 2px 4px 25px var(--boxShadow);

	@media (max-width: 600px) {
		width: 14rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 8rem;
	}
	&:hover {
		transform: translateY(-5px);
		transition: all 0.3s ease-in-out;
	}
`;

export default function DownloadPage() {
  /*  useEffect(() => {
        try{
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/android/i.test(userAgent)) {
                window.location.replace("market://details?id=com.valos.ons");
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                window.location.replace("itms-apps://itunes.apple.com/app/id6443761792");
            }
        }catch{
            //ignore
        }

    }, []);*/
    return (
        <Container id='download_Section'>
            <Image src={ImageProp} alt='Ons Patter Image' />
            <Wrapper>
                <TextWrapper>
                    <Header>
                        <HeaderColor>Download </HeaderColor>
                        Now to Connect
                    </Header>
                    <Description>
                        Be a part of this Friendly and Loving Environment where you will be able to find people with
                        similar interest and located near to you.
                    </Description>
                    <ButtonWrapper>
                        <a href='https://play.google.com/store/apps/details?id=com.valos.ons' target='_blank' rel='noopener noreferrer'>
                            <ButtonImage src={GoogleButton} alt='Ons Google Button' />
                        </a>
                        <a href='https://apps.apple.com/us/app/ons-map-based-live-dating/id6443761792' target='_blank' rel='noopener noreferrer'>
                            <ButtonImage src={AppleButton} alt='Ons Apple Button' />
                        </a>
                    </ButtonWrapper>
                </TextWrapper>
            </Wrapper>
        </Container>
    );
}
