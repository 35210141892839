import React from "react";
import styled from "styled-components";
import Slider from "react-slick";

// slider css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// images
import Image1 from "../../assets/reviews/Image1.png";
import Image2 from "../../assets/reviews/Image2.png";
import Image3 from "../../assets/reviews/Image3.png";
import Image4 from "../../assets/reviews/Image4.png";
import Image5 from "../../assets/reviews/Image5.png";

// styled
const Container = styled.section`
	padding-block: 6rem;

	@media (min-width: 601px) and (max-width: 1024px) {
		padding-block: 4rem;
	}
`;

const Wrapper = styled.div`
	width: 85%;
	margin: auto;

	@media (max-width: 600px) {
		width: 95%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const HeaderContainer = styled.div`
	text-align: center;
	margin-bottom: 6rem;

	@media (max-width: 600px) {
		margin-bottom: 4rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		margin-bottom: 4rem;
	}
`;

const Header = styled.h2`
	font-size: 2.5rem;
	font-weight: 700;
	letter-spacing: 1px;

	@media (max-width: 600px) {
		font-size: 2rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.8rem;
	}
`;

const HeaderColor = styled.span`
	color: var(--MainColor);
`;

const SliderContainer = styled.div``;

const SliderBox = styled.div`
	text-align: center;
`;

const SliderImage = styled.img`
	height: 100%;
	width: 18rem;
	object-fit: contain;
	margin: auto;

	@media (max-width: 600px) {
		width: 18rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 11rem;
	}
`;

export default function Reviews() {
	var settings = {
		dots: true,
		arrow: false,
		infinite: true,
		speed: 1000,
		autoplay: true,
		autoplaySpeed: 2000,
		slidesToShow: 3,
		slidesToScroll: 1,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const data = [
		{
			name: "Review Image 1",
			image: Image1,
		},
		{
			name: "Review Image 2",
			image: Image2,
		},
		{
			name: "Review Image 3",
			image: Image3,
		},
		{
			name: "Review Image 4",
			image: Image4,
		},
		{
			name: "Review Image 5",
			image: Image5,
		},
	];

	return (
		<Container>
			<Wrapper>
				<HeaderContainer>
					<Header>
						Honest<HeaderColor> Reviews</HeaderColor>
					</Header>
				</HeaderContainer>

				<SliderContainer>
					<Slider {...settings}>
						{data.map((item, index) => (
							<SliderBox key={index}>
								<SliderImage src={item.image} alt={item.name} loading='lazy' />
							</SliderBox>
						))}
					</Slider>
				</SliderContainer>
			</Wrapper>
		</Container>
	);
}
