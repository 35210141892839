import React from "react";

// components
import HeaderSection from "./Header";
import DetailSection from "./Details";

export default function Index() {
	return (
		<>
			<HeaderSection />
			<DetailSection />
		</>
	);
}
