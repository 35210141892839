import React from "react";
import styled from "styled-components";

// styled
const ButtonStyle = styled.button`
	/* color */
	color: var(--whiteColor);
	background: linear-gradient(90deg, #dd2525 0%, #ff7a00 100%);
	/* font */
	font-family: "Montserrat";
	font-size: var(--PcFontSize);
	font-weight: 600;
	/* attr */
	border: none;
	border-radius: 1.5rem;
	box-shadow: 1px 2px 25px var(--boxShadow);
	cursor: pointer;
	padding: 1.2rem 2rem;

	@media (max-width: 600px) {
		font-size: var(--MobileFontSize);
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
		padding: 0.8rem 2rem;
	}
	&:hover {
		transform: scale(1.03);
		transition: 0.3s all ease-in-out;
	}
`;

export default function OrangeButton({ href, children }) {
	return (
		<a href={href}>
			<ButtonStyle>{children}</ButtonStyle>
		</a>
	);
}
