import React from "react";
import styled from "styled-components";

import ImageProp from "../../assets/mobileProp/Group70-min.png";

// shared components
import OrangeButton from "../shared/OrangeButton";

// styles
const Container = styled.section`
	position: relative;
	z-index: 0;
	padding: 8rem 0 6rem 0;
	overflow: hidden;

	@media (max-width: 600px) {
		padding: 6rem 0 4rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 4rem 0;
	}
`;

const Image = styled.img`
	/* position */
	position: absolute;
	top: 15%;
	right: 0;
	z-index: -1;
	/* size */
	height: auto;
	width: 43%;

	@media (max-width: 600px) {
		position: relative;
		width: 100%;
		margin: auto;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 45%;
		top: 15%;
	}
`;

const Wrapper = styled.div`
	width: 85%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const TextWrapper = styled.div`
	width: 55%;

	@media (max-width: 600px) {
		width: 100%;
		text-align: center;
		margin: 4rem auto 0 auto;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 50%;
	}
`;

const Header = styled.h2`
	font-size: 6rem;
	font-weight: 800;
	letter-spacing: 1px;
	line-height: 1.2;

	@media (max-width: 600px) {
		font-size: 3.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 3.2rem;
	}
`;

const HeaderColor = styled.span`
	color: var(--MainColor);
`;

const Description = styled.p`
	color: var(--lightDescriptionColor);
	font-size: var(--PcFontSize);
	margin-top: 2rem;
	font-weight: 400;
	opacity: 0.7;
	line-height: 1.5;
	letter-spacing: 0.5px;
	width: 90%;

	@media (max-width: 600px) {
		width: 100%;
		margin: 2rem auto 2rem auto;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
		width: 90%;
		margin-top: 1rem;
	}
`;

const ButtonWrapper = styled.div`
	margin-top: 5rem;

	@media (min-width: 601px) and (max-width: 1024px) {
		margin-top: 3rem;
	}
`;

export default function Hero() {
	return (
		<Container>
			<Image src={ImageProp} alt='Ons Hero Image' />
			<Wrapper>
				<TextWrapper>
					<Header>
						<HeaderColor>Meet </HeaderColor>
						The Right
						<HeaderColor> Person</HeaderColor>
					</Header>
					<Description>
						Numerous matches have the satisfaction of discovering a partnership. Happy couples have the same
						outcomes, but their journeys are frequently extremely different. Every love story motivates us
						to keep becoming better, so we like hearing from you. Whether it's a first date or the absolute
						greatest news of an engagement, marriage, or child's birth, it doesn't matter.
					</Description>
					<ButtonWrapper>
						<OrangeButton href='#download_Section'>Download Now</OrangeButton>
					</ButtonWrapper>
				</TextWrapper>
			</Wrapper>
		</Container>
	);
}
