import React from "react";
import {Routes, Route, useLocation} from "react-router-dom";

// shared components
import NavBar from "./components/shared/NavBar";
import Footer from "./components/shared/Footer";

// components
import Home from "./components/home/Index";
import ContactUs from "./components/contact/Index";
import TermConditionData from "./components/routes/TermCondition";
import CookiesPolicy from "./components/routes/CookiesPolicy";
import PrivacyPolicy from "./components/routes/PrivacyPolicy";
import DownloadPage from "./components/routes/DownloadPage";

function App() {
	const location = useLocation();
	return (
		<div>
			{
				(location.pathname !== "/download-app")
				&&<NavBar />
			}

			<Routes>
				<Route index element={<Home />} />
				<Route exact path='/' element={<Home />} />
				<Route exact path='/contact' element={<ContactUs />} />
				<Route exact path='/term-and-conditions' element={<TermConditionData />} />
				<Route exact path='/cookies-policy' element={<CookiesPolicy />} />
				<Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
				<Route exact path='/download-app' element={<DownloadPage />} />
			</Routes>
			{
				(location.pathname !== "/download-app")
				&&<Footer />
			}


		</div>
	);
}

export default App;
