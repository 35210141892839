import React from "react";
import styled from "styled-components";

// components
import Hero from "./Hero";
import About from "./About";
import Features from "./Features";
import TextFirstGrid from "./TextFirstGrid";
import ImageFirstGrid from "./ImageFirstGrid";
import Download from "./Download";
import Reviews from "./Reviews";
import Contact from "./Contact";

// styled
const MainContainer = styled.main`
	overflow: hidden;
`;

export default function Index() {
	return (
		<MainContainer>
			<Hero />
			<About />
			<Features />
			<TextFirstGrid />
			<ImageFirstGrid />
			<Download />
			<Reviews />
			<Contact />
		</MainContainer>
	);
}
