import React from "react";
import styled from "styled-components";

// data
import { CookiesPrivacyData } from "./Data";

// styled
const Container = styled.section`
	padding: 10rem 0 2rem 0;
	overflow: hidden;

	@media (max-width: 600px) {
		padding: 6rem 0 2rem 0;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding: 8rem 0 2rem 0;
	}
`;

const Wrapper = styled.div`
	width: 80%;
	margin: auto;

	@media (max-width: 600px) {
		width: 90%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 85%;
	}
`;

const HeaderWrapper = styled.div`
	margin-bottom: 2rem;
`;

const HeaderText = styled.h2`
	font-size: 3rem;
	font-weight: 800;
	letter-spacing: 1px;
	margin-bottom: 2rem;

	@media (max-width: 600px) {
		font-size: 1.6rem;
		text-align: center;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.4rem;
	}
`;

const HeaderColor = styled.span`
	color: var(--MainColor);
`;

const Slogan = styled.p`
	font-size: var(--PcFontSize);
	font-weight: 800;
	letter-spacing: 0.5px;
	opacity: 0.7;
	line-height: 1.5;
	margin-bottom: 1rem;

	@media (max-width: 600px) {
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSize);
	}
`;

const Description = styled.p`
	font-size: var(--PcFontSize);
	letter-spacing: 0.5px;
	opacity: 0.7;
	line-height: 1.5;

	@media (max-width: 600px) {
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
	}
`;

const TextBox = styled.div`
	margin-block: 4rem;

	@media (min-width: 601px) and (max-width: 1024px) {
		margin-block: 2rem;
	}
`;

const QuestionText = styled.h3`
	font-size: 1.4rem;
	margin-bottom: 2rem;
	letter-spacing: 0.5px;

	@media (max-width: 600px) {
		font-size: 1.2rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.8rem;
		margin-bottom: 1rem;
	}
`;

const ParagraphText = styled.p`
	margin-block: 1rem;
	line-height: 1.5;
	opacity: 0.7;
	letter-spacing: 0.5px;

	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
	}
	&:hover {
		opacity: 1;
		transition: all 0.2s ease-in-out;
	}
`;

const ListItemBox = styled.div`
	margin-block: 1rem;
`;

const ListItem = styled.li`
	margin-block: 0.5rem;
	line-height: 2;
	opacity: 0.7;
	letter-spacing: 0.5px;

	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: var(--TabletFontSizeSmall);
	}
`;

export default function CookiesPolicy() {
	return (
		<Container>
			<Wrapper>
				<HeaderWrapper>
					<HeaderText>
						COOKIES<HeaderColor> POLICY</HeaderColor>
					</HeaderText>

					<Slogan>Welcome to Ons!</Slogan>
					<Description>
						Ons is owned and operated by Valos IT LTD.
						<br />
						This cookie policy explains how and why cookies and other similar technologies may be stored on
						and accessed from your device when you use or visit:
						<br />
					</Description>

					<ListItemBox>
						<ListItem>
							<strong>https://ons-app.com</strong>
						</ListItem>
					</ListItemBox>
					<Description>
						(Hereinafter referred to as “Ons”).
						<br />
						<br />
						The information collected through cookies will be under responsibility and in charge of:
						<br />
						<br />
					</Description>

					<ListItemBox>
						<ListItem>
							<strong>Valos IT LTD.</strong>
						</ListItem>
						<ListItem>
							<strong>Email : ons-support@valosit.co.uk</strong>
						</ListItem>
					</ListItemBox>

					<Description>
						This cookie policy should be read together with our privacy policy and our terms and conditions.
						By using the website, you accept the use of cookies by Ons, in the terms contained in this
						policy.
					</Description>
				</HeaderWrapper>

				{CookiesPrivacyData.map(({ number, question, Answer, List, BoldList, BoldListDescription }, index) => (
					<TextBox key={index}>
						<QuestionText>
							<HeaderColor>{number} </HeaderColor>
							{question}
						</QuestionText>

						{Answer.map((item, id) => (
							<ParagraphText key={id}>{item.paragraph}</ParagraphText>
						))}
						{List.map((item, id) => (
							<ListItem key={id}>{item.listItem}</ListItem>
						))}
					</TextBox>
				))}
			</Wrapper>
		</Container>
	);
}
